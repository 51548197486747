import { SignInAlt } from "icons/font-awesome/regular"
import { Response, ResponseError, useAuthApi, useUserApi } from "modules/api-client"
import { useAuth, User } from "modules/auth"
import { useTranslations } from "modules/translations"
import useConfig from "next/config"
import { useRouter } from "next/router"
import { ReactNode, useEffect, useState } from "react"
import Errors from "ui-kit/elements/errors"
import HyperLink from "ui-kit/elements/hyper-link"
import FormLayout from "ui-kit/forms/form-layout"
import Input from "ui-kit/forms/input"
import SubmitButton from "ui-kit/forms/submit-button"
import Container from "ui-kit/layout/container"

export default function Login (): ReactNode {

    const { loginRequest } = useAuthApi()
    const { currentUserRequest } = useUserApi()
    const { user, login, pendingRedirect, logout } = useAuth()
    const { defaultRoute } = useConfig().publicRuntimeConfig as { defaultRoute: string }
    const { push } = useRouter()
    const { translate } = useTranslations()

    const [username, setUsername] = useState<string>( "" )
    const [password, setPassword] = useState<string>( "" )
    const [twoFactorCode, setTwoFactorCode] = useState<string>( "" )
    const [error, setError] = useState<ResponseError>()
    const [isLoading, setIsLoading] = useState<boolean>( false )
    const [isThrottled, setIsThrottled] = useState<boolean>( false )
    const [showTwoFactorField, setShowTwoFactorField] = useState<boolean>( false )
    const [loggedIn, setLoggedIn] = useState<boolean>( false )

    function doLogin ( event: React.FormEvent ): void {
        event.preventDefault()
        setError( undefined )
        setIsLoading( true )
        loginRequest(
            { username, password, two_factor_code: twoFactorCode },
            { notifyOnError: false, logoutOnUnauthorized: false },
        ).then( response => {
            if ( response.success && response.data !== undefined ) {
                setIsLoading( false )
                login( response.data.user, response.data.token, response.data.chat )
            } else {
                if ( response.error?.errors?.two_factor_code && !showTwoFactorField ) {
                    setIsThrottled( false )
                    setShowTwoFactorField( true )
                } else if ( response.statusCode === 429 ) {
                    setIsThrottled( true )
                }else {
                    setIsThrottled( false )
                    setError( response.error )
                }
            }
        } )
    }

    useEffect( () => {
        let isUserSet = true

        // User is not defined, attempt to revive the session
        if( !loggedIn ) {
            setIsLoading( true )
            currentUserRequest().then( ( response: Response<User> ) => {
                if( response.statusCode === 401 ) {
                    setLoggedIn( true )
                    logout()
                }
                if ( response.success && response.data ) {
                    login( response.data )
                }
                // Make sure we do not attempt to set the state after unmounting
                if ( isUserSet ) {
                    setIsLoading( false )
                }
            } )
        }

        if ( user ) {
            push( pendingRedirect || defaultRoute )
            return
        }

        return () => {
            isUserSet = false
            setIsLoading( false )
        }

    }, [push, user, login, pendingRedirect, defaultRoute, currentUserRequest, isLoading, loggedIn, logout] )

    return <>
        <h1 className="mb-16 text-3xl font-black text-center">
            {translate( "pages.login.login" )}
        </h1>
        <Container size="small">
            <form noValidate onSubmit={doLogin}>
                <FormLayout>
                    <Input
                        id="username"
                        label={translate( "pages.login.email" )}
                        onChange={value => setUsername( value )}
                        errors={error?.errors?.username}
                        type="email"
                        value={username}
                    />
                    <Input
                        id="password"
                        label={translate( "pages.login.password" )}
                        onChange={value => setPassword( value )}
                        errors={error?.errors?.password}
                        type="password"
                        value={password}
                    />

                    {showTwoFactorField &&
                        <Input
                            id="two_factor_code"
                            label={translate( "pages.login.2fa" )}
                            onChange={value => setTwoFactorCode( value )}
                            errors={error?.errors?.two_factor_code}
                            type="text"
                            value={twoFactorCode}
                        />}

                    <Errors errors={isThrottled ? translate( "pages.login.throttled" ) : error?.message} />

                    <div className="flex items-center self-stretch justify-between max-w-sm">
                        <SubmitButton icon={SignInAlt} loading={isLoading}> {translate( "pages.login.login" )} </SubmitButton>
                        <div className="flex flex-col items-end space-y-1">
                            <HyperLink href="/password/reset">{translate( "pages.login.forgot-password" )}</HyperLink>
                        </div>
                    </div>
                </FormLayout>
            </form>
        </Container>
    </>
}
