import React, { ReactNode } from "react"

export interface FormLayoutProps {
    /**
     * Additional CSS classes to add to this component.
     */
    className?: string

    children: ReactNode
}

export default function FormLayout ( props: FormLayoutProps ): JSX.Element {
    return (
        <div className={`flex flex-col items-start space-y-6 ${props.className || ""}`}>
            {props.children}
        </div>
    )
}
